import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { Row, Container } from "reactstrap";
import { Select, Button, Input, InputNumber, Radio, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
	getQuestionnaire,
	saveAnswer,
	setUserData,
} from "../../features/survey/surveySlices";
import Step1 from "./steps/Step1";
import Step8 from "./steps/Step8";
import Step9 from "./steps/Step9";
import "./style.css";
import logo from "../../assets/images/survey/logo.svg";
import { v4 as uuidv4 } from "uuid";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import Header from "../../Components/Application/Survey/Header";
import Stepper from "../../Components/Application/Survey/Stepper";

const Survey = () => {
	const [currentStep, setCurrentStep] = useState(0);
	const [isNextDisabled, setIsNextDisabled] = useState(true);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const data = useSelector((state) => state.survey.data);
	const answers = useSelector((state) => state.survey.answers);
	const currentLanguage = localStorage.getItem("surveyLanguage");

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		setLanguage(lng);
		localStorage.setItem("surveyLanguage", lng);
	};
	const [language, setLanguage] = useState(currentLanguage || i18n.language);

	useEffect(() => {
		console.log(data);
	}, [data]);

	useEffect(() => {
		if (data?.steps?.length === 0) return;
		if (localStorage.getItem("surveyComplete")) {
			setCurrentStep(data?.steps?.length + 2);
			setLanguage(currentLanguage);
			i18n.changeLanguage(currentLanguage);
		}
	}, [data]);

	useEffect(() => {
		const storedSessionId = localStorage.getItem("surveySessionId");
		if (storedSessionId) {
			const storedAnswers = JSON.parse(localStorage.getItem("surveyAnswers"));
			console.log(storedAnswers);
			if (storedAnswers) {
				Object.entries(storedAnswers).forEach(([stepId, questions]) => {
					Object.entries(questions).forEach(([questionId, answer]) => {
						dispatch(
							saveAnswer({
								stepId: parseInt(stepId),
								questionId: parseInt(questionId),
								answer,
							})
						);
					});
				});
			}
		} else {
			const newSessionId = uuidv4();
			localStorage.setItem("surveySessionId", newSessionId);
		}
		dispatch(getQuestionnaire());
	}, [dispatch]);

	useEffect(() => {
		validateStep();
	}, [currentStep, answers]);
	const userData = useSelector((state) => state?.survey.userData);
	const changeStep = (operation) => {
		if (operation === "+") {
			setCurrentStep(currentStep + 1);
			localStorage.setItem("surveyAnswers", JSON.stringify(answers));
		} else {
			if (currentStep === 0) return;
			setCurrentStep(currentStep - 1);
		}
	};

	const handleAnswerChange = (stepId, questionId, answer) => {
		dispatch(saveAnswer({ stepId, questionId, answer }));
		const updatedAnswers = {
			...answers,
			[stepId]: {
				...answers[stepId],
				[questionId]: answer,
			},
		};
		localStorage.setItem("surveyAnswers", JSON.stringify(updatedAnswers));
	};

	const validateStep = () => {
		const stepData = data.steps?.[currentStep - 1];
		if (!stepData) return;

		const allRequiredAnswered = stepData.questions.every((question) => {
			if (question.required === 1) {
				const answer = answers[stepData.id]?.[question.id];

				// Controlla se la domanda è di tipo 'number' e se il valore è maggiore di 18
				if (question.type === "number") {
					console.log(answer);
					return answer?.value != null && answer?.value >= 18;
				}

				// Per tutti gli altri tipi di domanda
				return answer != null;
			}
			return true;
		});

		setIsNextDisabled(!allRequiredAnswered);
	};

	const renderStepContent = (stepData) => {
		return (
			<>
				<Stepper
					currentStep={currentStep}
					stepsData={data?.steps}
				/>

				<div className='surver-step-container-questions'>
					<div className='survey-content'>
						<p className='survey-title'>{stepData?.[`title_${language}`]}</p>
						{stepData.questions.map((question, index) => (
							<div
								key={index}
								style={{ marginBottom: "30px", width: "inherit" }}
							>
								<p className='input-description'>
									{question?.[`title_${language}`]}
								</p>
								<p className='input-subtitle'>
									{question?.[`description_${language}`]}
								</p>
								{question.type === "text" && (
									<Input
										placeholder={question?.[`title_${language}`]}
										className='survey-input'
										value={answers[stepData.id]?.[question.id]?.value || ""}
										onChange={(e) => {
											handleAnswerChange(stepData.id, question.id, {
												value: e.target.value,
											});
											if (!!question?.store_id)
												dispatch(
													setUserData({
														value: e.target.value,
														id: question.store_id,
													})
												);
										}}
									/>
								)}
								{question.type === "number" && (
									<InputNumber
										maxLength={2}
										placeholder={question?.[`title_${language}`]}
										className='survey-input'
										formNoValidate
										style={{ width: "100%" }}
										defaultValue={
											answers[stepData.id]?.[question.id]?.value || null
										}
										onChange={(value) =>
											handleAnswerChange(stepData.id, question.id, { value })
										}
									/>
								)}
								{question.type === "radio" && (
									<Radio.Group
										className='custom-radio-group'
										style={{
											flexDirection:
												stepData?.questions.length > 1 ? "row" : "column",
											gap: "20px",
											alignItems: "flex-start",
										}}
										value={answers[stepData.id]?.[question.id]?.id || null}
										onChange={(e) =>
											handleAnswerChange(stepData.id, question.id, {
												id: e.target.value,
											})
										}
									>
										{question.options.map((option, idx) => (
											<Radio
												key={idx}
												value={option.id}
												className='custom-radio'
											>
												{option?.[`title_${language}`]}
											</Radio>
										))}
									</Radio.Group>
								)}
								{question.type === "checkbox" && (
									<Checkbox.Group
										className='two-column-checkbox-group'
										value={
											answers[stepData.id]?.[question.id]?.map((a) => a.id) ||
											[]
										}
										onChange={(checkedValues) =>
											handleAnswerChange(
												stepData.id,
												question.id,
												checkedValues.map((id) => ({ id }))
											)
										}
									>
										{question.options.map((option, idx) => (
											<Checkbox
												key={idx}
												value={option.id}
												className='custom-checkbox'
											>
												{option?.[`title_${language}`]}
											</Checkbox>
										))}
									</Checkbox.Group>
								)}
							</div>
						))}
					</div>
				</div>
			</>
		);
	};

	return (
		<Container style={{ width: "100vw", height: "100vh" }}>
			{/* Header */}
			<Header
				changeLanguage={changeLanguage}
				currentLanguage={language}
			/>

			<Row
				style={{
					height: "76vh",
					backgroundColor: "#F2F2F2",
					width: "100vw",
					overflowY: "auto",
				}}
			>
				{currentStep === 0 && <Step1 />}
				{currentStep > 0 &&
					currentStep < data?.steps?.length + 1 &&
					renderStepContent(data?.steps[currentStep - 1])}
				{currentStep === data?.steps?.length + 1 && (
					<Step8 goBack={setCurrentStep} />
				)}
				{currentStep === data?.steps?.length + 2 && (
					<Step9 goBack={setCurrentStep} />
				)}
			</Row>

			{currentStep !== data?.steps?.length + 1 && (
				<Row className='footer-row'>
					{currentStep !== data?.steps?.length + 2 && (
						<>
							<Button
								className='footer-button-back'
								onClick={() => changeStep("-")}
							>
								{currentStep === 0 ? t("footer.cancel") : t("footer.back")}
							</Button>
							<Button
								className='footer-button-next'
								onClick={() => changeStep("+")}
								disabled={currentStep !== 0 && isNextDisabled}
							>
								{currentStep === data?.steps?.length + 2
									? t("footer.finish")
									: currentStep === 0
									? t("footer.next")
									: t("footer.continue")}
							</Button>
						</>
					)}
				</Row>
			)}
		</Container>
	);
};

export default Survey;
