import React, { useEffect } from "react";

import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setChangeEmail } from "../../../features/survey/surveySlices";
import { insertNewTokenAction } from "../../../features/user/userSlices";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";

const Step1 = ({ goBack }) => {
	const dataUser = useSelector((state) => state.survey.userData);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const handleGoBack = () => {
		dispatch(setChangeEmail(true));
		goBack((prev) => prev - 1);
	};

	useEffect(() => {
		localStorage.setItem("surveyComplete", "true");
	}, []);

	const handleResend = () => {
		dispatch(
			insertNewTokenAction({
				language: i18n.language,
				session: localStorage.getItem("surveySessionId"),
			})
		);
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
			}}
		>
			<div className='survey-content'>
				<p className='survey-title'>{t("verifyAccount.title")}</p>
				<p
					style={{
						textAlign: "left",
						width: "100%",
						color: "#477673",
						fontSize: "16px",
						fontWeight: "600",
					}}
				>
					{t("verifyAccount.subtitle")} {dataUser.email}
					<br />
					{t("verifyAccount.subtitle1")}
				</p>
				<div className='survey-steps'>
					{/* 	<p
						style={{
							fontSize: 16,
							fontWeight: 600,
							color: "#003F49",
							marginTop: 20,
						}}
					>
						{t("verifyAccount.subtitle2")}
					</p> */}
					<div style={{ marginTop: 20 }}>
						{/* 					<Button
							style={{
								backgroundColor: "#DEDED1",
								borderRadius: 15,
								height: 60,
								color: "#003F49",
								fontWeight: 600,
								fontSize: 16,
								border: "none",
								marginRight: 20,
								marginBottom: 20,
							}}
							onClick={handleResend}
						>
							{t("verifyAccount.resend")}
						</Button> */}
						{/* 	<Button
							onClick={handleGoBack}
							style={{
								backgroundColor: "#DEDED1",
								borderRadius: 15,
								height: 60,
								color: "#003F49",
								fontWeight: 600,
								fontSize: 16,
								border: "none",
								marginRight: 20,
							}}
						>
							{t("verifyAccount.changeEmail")}
						</Button> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Step1;
