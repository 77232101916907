import React from "react";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import glass from "../../../assets/images/survey/glass.svg";
import tasksquare from "../../../assets/images/survey/task-square.svg";
import useroctagon from "../../../assets/images/survey/user-octagon.svg";
import "../style.css";
const steps = [
	{
		description: "steps.0.description",
		icon: glass,
	},
	{
		description: "steps.1.description",
		icon: tasksquare,
	},
	/* 	{
		description: "steps.2.description",
		icon: useroctagon,
	}, */
];

const Step1 = () => {
	const { t } = useTranslation();

	return (
		<div className='surver-step-container'>
			<div className='survey-content'>
				<p className='survey-title'>{t("survey-title")}</p>
				{/* 			<p className='survey-subtitle'>{t("survey-subtitle")}</p> */}
				<div
					className='survey-step'
					style={{ paddingLeft: 0, gap: "30px", height: "auto" }}
				>
					{steps.map((step, index) => (
						<div
							className='survey-steps-intro'
							key={index}
							style={{ height: "fit-content" }}
						>
							<ReactSVG
								src={step.icon}
								className='step-icon'
							/>
							<div className='step-divider'></div>
							<p className='survey-description'>
								{t(step.description)}{" "}
								{/* Traduzione dinamica delle descrizioni */}
							</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Step1;
